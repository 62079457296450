@import "variables";

$maxWidth: 1240px;

/* common */
@keyframes pop {
    0% {
        opacity: 0;
        transform: translate3d(0px, 50px, 0px);
    }
    100% {
        opacity: 1;
        transform: translate3d(0px, 0px, 0px);
    }
}
.pop {
    visibility: visible !important;
    opacity: 0;
    transform: translate3d(0px, 50px, 0px);
    animation: 0.5s ease-in-out 0s 1 normal forwards running pop;
}

.wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.section {
    width: 100%;
}
.inner {
    gap: 240px;
    width: 100%;
    max-width: $maxWidth;
    margin: 0 auto;
    padding: 7.5rem 60px;

    @media (max-width: $breakPoint) {
        gap: 80px !important;
        padding: 7rem 20px;
    }
}

.contact_btn {
    display: flex;
    align-items: center;
    gap: 8px;
    position: fixed;
    right: 52px;
    bottom: 46px;
    padding: 12px 24px;
    border-radius: 16px;
    background-color: #4A4A4A;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.20);
    font-size: 16px;
    font-weight: 700;
    color: white;
    opacity: 0;
    z-index: 999;
    transition: opacity .2s linear;

    &.visible {
        opacity: 1;
    }

    @media (max-width: $breakPoint) {
        right: 24px;
        bottom: 24px;
        padding: 12px 16px;
    }
}

/* component */
.title_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;
    white-space: pre-line;

    @media (max-width: $breakPoint) {
        gap: 8px;
    }

    .label {
        background: linear-gradient(95deg, #00B8FF 4.75%, #612DF5 63.95%, rgba(97, 45, 245, 0.80) 102.04%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 20px;
        font-weight: 400;

        @media (max-width: $breakPoint) {
            font-size: 14px;
        }
    }

    .title {
        padding-bottom: 0.5rem;
        font-size: 40px;
        font-weight: 800;
        color: #1B1B1B;

        @media (max-width: $breakPoint) {
            font-size: 20px;
            padding-bottom: unset;
        }
    }
    .subtitle {
        font-size: 20px;
        font-weight: 400;
        color: $text-black;

        @media (max-width: $breakPoint) {
            font-size: 14px;
        }
    }
}


/* landing section */
.landing {
    width: 100%;
    height: calc(100svh - 80px);

    @media (max-width: $breakPoint) {
        height: unset;
    }
}
.landing .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: unset;
    width: 100%;
    height: 100%;
    padding: 0 60px 80px;
    color: $text-black;
    white-space: pre-line;

    @media (max-width: $breakPoint) {
        flex-direction: column;
        gap: 0 !important;
        padding: 7rem 20px;
    }

    .text_area {
        display: flex;
        flex-direction: column;

        @media (max-width: $breakPoint) {
            align-items: center;
            margin: 0 0 80px;
            text-align: center;
        }
    }

    h2 {
        font-size: 44px;
        font-weight: 900;
        line-height: 133%;

        &:lang(en) {
            @media (max-width: $breakPoint) {
                font-size: 28px;
            }
        }

        @media (max-width: $breakPoint) {
            font-size: 32px;
        }
    }

    h3 {
        margin-top: 3rem;
        font-size: 20px;
        font-weight: 300;
        line-height: 150%;

        @media (max-width: $breakPoint) {
            font-size: 14px;
        }
    }

    .btn_area {
        display: flex;
        gap: 1rem;
        padding-top: 1.5rem;

        & button, & a {
            display: flex;
            align-items: center;
            gap: 8px;
            width: fit-content;
            height: fit-content;
            padding: 16px 24px 16px 32px;
            border-radius: 1rem;
            font-size: 20px;
            font-weight: 700;

            @media (max-width: $breakPoint) {
                padding: 14px 20px;
                font-size: 16px;
            }
        }

        .start_btn {
            background-color: $primary;
            color: white;
        }
        .business_link {
            padding: 16px 24px;
            border: 1px solid $text-black;
            background-color: white;
            color: $text-black;

            @media (max-width: $breakPoint) {
                padding: 13px 20px;
            }
        }
    }
}

.landing .video_area {
    position: relative;
    width: 38.6vw;
    max-width: 34.75rem;
    aspect-ratio: 16 / 9;
    border-radius: 16px;
    overflow: clip;

    @media (max-width: $breakPoint) {
        position: relative;
        top: unset;
        right: unset;
        transform: unset;
        width: 100%;
    }

    .video {
        position: relative;
        width: 100%;
        height: auto;
        border-radius: 14px;
    }

    .control_btn {
        display: flex;
        align-items: center;
        gap: 8px;
        position: absolute;
        right: 56px;
        bottom: 32px;
        padding: 4px 12px;
        border-radius: 32px;
        background-color: white;
        font-size: 14px;
        font-weight: 600;
        color: $textBlack;
        z-index: 2;

        &::before {
            content: '';
            @include icon('/images/home/landing/no-sound.svg', 24px, 24px);
        }

        &[name=muted]::before {
            @include icon('/images/home/landing/sound.svg', 24px, 24px);
        }

        @media (max-width: $breakPoint) {
            right: 28px;
            bottom: 16px;
        }
    }
}

/* custom voice section */
.custom_voice .inner {
    display: flex;
    flex-direction: column;
    gap: 160px;

    .steps {
        display: flex;
        flex-direction: column;
        gap: 160px;

        @media (max-width: $breakPoint) {
            gap: 48px;
        }
    }

    .step {
        display: flex;
        justify-content: space-between;
        visibility: hidden;
        white-space: pre-line;

        @media (max-width: $breakPoint) {
            flex-direction: column;
            gap: 16px;
            align-items: center;
            text-align: center;
        }

        &:nth-child(2n) {
            .title {
                text-align: right;

                @media (max-width: $breakPoint) {
                    text-align: center;
                }
            }
        }

        .text_area {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            @media (max-width: $breakPoint) {
                align-items: center;
                gap: 16px;
            }

            .cta_btn {
                display: flex;
                align-items: center;
                gap: 8px;
                width: fit-content;
                margin-top: 2.5rem;
                padding: 14px 16px 14px 24px;
                border-radius: 16px;
                background-color: $text-black;
                font-size: 1rem;
                font-weight: 700;
                color: white;

                @media (max-width: $breakPoint) {
                    padding: 14px 16px;
                }
            }

            .label {
                width: fit-content;
                margin-top: 1rem;
                font-size: 1rem;
                font-weight: 700;
                line-height: 175%;
                color: $text-black;

                @media (max-width: $breakPoint) {
                    margin-top: 2rem;
                    line-height: 200%;
                }
            }

            ul {
                display: flex;
                flex-direction: column;

                li {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 200%;

                    @media (max-width: $breakPoint) {
                        font-size: 12px;
                        line-height: 20px;
                        text-align: left;
                    }
                }
            }
        }

        .title {
            padding-top: 3rem;
            font-size: 32px;
            font-weight: 800;
            line-height: 125%;
            color: $text-black;

            @media (max-width: $breakPoint) {
                padding-top: unset;
                font-size: 16px;
                line-height: 150%;
            }
        }

        &:nth-child(1) {
            img {
                width: 46vw;
                max-width: 778px;
                margin-right: -57px;

                @media (max-width: $breakPoint) {
                    width: 92%;
                    margin-right: 0;
                }
            }
        }
        &:nth-child(2) {
            @media (max-width: $breakPoint) {
                flex-direction: column-reverse;
            }
            img {
                width: 40vw;
                max-width: 688px;
                margin-left: -54px;

                @media (max-width: $breakPoint) {
                    width: 92%;
                    margin-left: 0;
                }
            }
        }
        &:nth-child(3) {
            img {
                width: 24vw;
                max-width: 383px;
                margin-right: -42px;

                @media (max-width: $breakPoint) {
                    width: 76%;
                    margin-right: 0;
                }
            }
        }
    }
}

/* feature section */
.feature {
    visibility: hidden;
}
.feature .inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 160px;
}
.feature .feature_list {
    display: flex;
    flex-direction: column;
    gap: 160px;
    width: 100%;

    @media (max-width: $breakPoint) {
        gap: 80px;
    }

    .feature_item {
        display: flex;
        position: relative;
        visibility: hidden;

        @media (max-width: $breakPoint) {
            flex-direction: column-reverse;
        }

        img {
            position: absolute;
            height: auto;
            visibility: hidden;

            @media (max-width: $breakPoint) {
                position: relative;
                width: 84vw !important;
                top: unset !important;
                left: 50% !important;
                right: unset !important;
                transform: translateX(-50%) !important;
            }
        }

        &.pop img {
            visibility: visible !important;
            opacity: 0;
            transform: translate3d(0px, 50px, 0px);
            animation: 0.5s ease-in-out 0.5s 1 normal forwards running pop;

            @media (max-width: $breakPoint) {
                opacity: 1;
                animation: unset;
            }
        }

        .text_area {
            display: flex;
            flex-direction: column;
            position: relative;
            color: $text-black;

            @media (max-width: $breakPoint) {
                gap: 24px;
                align-items: center;
            }

            .title {
                padding-bottom: 1rem;
                font-size: 2rem;
                font-weight: 800;

                @media (max-width: $breakPoint) {
                    font-size: 16px;
                    padding-bottom: unset;
                }
            }
            .text {
                font-size: 1rem;
                font-weight: 400;
            }
            .cta_btn {
                display: flex;
                align-items: center;
                gap: 8px;
                width: fit-content;
                margin: 40px 0;
                padding: 14px 16px 14px 24px;
                border-radius: 16px;
                background-color: $text-black;
                font-size: 1rem;
                font-weight: 700;
                color: white;

                @media (max-width: $breakPoint) {
                    margin: unset;
                    font-size: 14px;
                }
            }
            .ai_feature_list {
                display: flex;
                flex-direction: column;
                font-size: 14px;
                font-weight: 400;
                line-height: 200%;
            }
        }
        &:nth-child(1) {
            justify-content: flex-end;

            @media (max-width: $breakPoint) {
                flex-direction: column;
            }

            img {
                top: -30px;
                left: -52px;
                max-width: 765px;
            }

            .text_area {
                align-items: flex-end;
                text-align: right;

                @media (max-width: $breakPoint) {
                    align-items: center;
                    text-align: left;
                }
            }
        }
        &:nth-child(2) {
            img {
                top: -34px;
                right: -60px;
                max-width: 731px;
            }
        }
    }
}

/* usage section */
.usage {
    visibility: hidden;
}
.usage .inner {
    display: flex;
    flex-direction: column;
    gap: 80px;

    .title_area {
        padding: 0 60px;

        @media (max-width: $breakPoint) {
            padding: 0 20px;
        }
    }

    .review_area {
        display: flex;
        flex-direction: column;
    }
}
@keyframes slideLeft {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-5280px);
    }
}
@keyframes slideRight {
    0% {
        transform: translateX(-1890px); // ( 246(item width) + 24(gap) ) * 7(콘텐츠 개수)
    }
    100% {
        transform: translateX(0);
    }
}
.usage .list_wrap {
    position: relative;
    width: 100%;

    &:nth-child(2) {
        margin-top: 24px;
        padding-bottom: 8px;
    }
}
.usage .review_list {
    display: flex;
    gap: 24px;
    width: calc(200% + 24px);
    animation: slideLeft 120s linear infinite;

    @media (max-width: $breakPoint) {
        gap: 16px;
        width: calc(200% + 16px);
    }

    .review_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 28px;
        flex: 0 0 456px;
        height: 240px;
        padding: 36px 20px 20px;
        border-radius: 24px;
        background-color: white;
        white-space: pre-line;
        text-align: center;
        box-shadow: 0 24px 57px 0 rgba(0, 0, 0, 0.1);

        @media (max-width: $breakPoint) {
            gap: 20px;
            flex: 0 0 calc(100vw - 56px);
            height: auto;
            padding: 36px 20px 20px;
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
        }

        .title {
            font-size: 1rem;
            font-weight: 700;
            white-space: nowrap;
        }

        .text {
            padding-bottom: 20px;
            font-size: 14px;
            font-weight: 300;

            @media (max-width: $breakPoint) {
                padding-bottom: 12px;
                font-size: 12px;
            }
        }

        .writer {
            font-size: 14px;
            font-weight: 400;
            b {
                font-weight: 700;
            }

            @media (max-width: $breakPoint) {
                font-size: 12px;
            }
        }
    }
}

.usage .usage_list {
    display: flex;
    gap: 24px;
    width: calc(200% + 24px);
    animation: slideRight 45s linear infinite;

    @media (max-width: $breakPoint) {
        gap: 16px;
        width: calc(200% + 16px);
    }

    .usage_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        flex: 0 0 246px;
        font-size: 14px;
        font-weight: 400;
        color: #9EA0A4;

        @media (max-width: $breakPoint) {
            gap: 16px;
            flex: 0 0 123px;
            height: auto;
            font-size: 12px;
        }

        img {
            @media (max-width: $breakPoint) {
                width: 123px;
                height: auto;
            }
        }
    }
}

/* plan section */
.plan {
    visibility: hidden;
}
.plan .inner {
    display: flex;
    flex-direction: column;
    gap: 10rem;

    @media (max-width: $breakPoint) {
        gap: 80px;
    }

    .plan_area {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media (max-width: $breakPoint) {
            gap: 2rem;
        }
    }

    .banner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 1.5rem 4rem;
        border-radius: 16px;
        background-color: $text-black;
        font-size: 20px;
        font-weight: 700;
        color: white;

        @media (max-width: $breakPoint) {
            padding: 24px;
            font-size: 1rem;
            white-space: pre-line;
        }

        a {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 14px 16px 14px 24px;
            border-radius: 16px;
            background-color: white;
            font-size: 14px;
            font-weight: 700;
            color: $text-black;
            white-space: nowrap;

            @media (max-width: $breakPoint) {
               padding: 12px 14px;
                font-size: 12px;
            }
        }
    }
}
.plan_list {
    display: flex;
    gap: 1rem;

    @media (max-width: $breakPoint) {
        gap: 32px;
        width: max-content;
        will-change: transform;
        transform: translate3d(0, 0, 0);
        @include scroll-style;
    }

    .plan_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1;
        min-height: 503px;
        padding: 32px 24px;
        border: 1px solid #CCCCCC;
        border-radius: 16px;

        @media (max-width: $breakPoint) {
            position: static;
            flex: unset;
            flex-shrink: 0;
            width: 100%;
            max-width: 240px;
        }

        &.highlight {
            border: 1px solid $primary;
        }

        .recommend {
            position: absolute;
            top: -32px;
            left: 0;
        }

        .name {
            padding-bottom: 0.5rem;
            font-size: 24px;
            font-weight: 900;
            color: $text-black;
        }
        .text {
            font-size: 12px;
            font-weight: 400;
            color: $text-black;
        }

        .price {
            margin: 3rem 0 1rem;

            & strong {
                font-size: 24px;
                font-weight: 700;
                color: $text-black;
            }
            & span {
                font-size: 12px;
                font-weight: 400;
                color: $text-black;
            }
        }

        hr {
            width: 100%;
            height: 1px;
            border: none;
            background-color: #ECECEC;
        }

        .target {
            padding: 1.5rem 0;
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            color: $text-black;
        }
        .feature_list {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .feature_item {
                display: flex;
                align-items: flex-start;
                position: relative;
                padding-left: 28px;
                font-size: 12px;
                font-weight: 400;
                line-height: 22px;
                color: #4F535A;
                white-space: nowrap;

                & strong {
                    display: inline;
                    width: fit-content;
                    font-weight: 700;
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    background: url("/images/plan/check.svg") 20px;
                }

                p {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    line-height: 22px;
                }
            }
        }
    }
}

/* promotion section */
.promotion {
    width: 100vw;
    background-color: $primary;
    margin: 160px 0 80px;

    @media (max-width: $breakPoint) {
        margin: 80px 0;
    }
}
.promotion .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    min-height: 480px;
    padding: 108px 60px 60px;
    color: white;

    @media (max-width: $breakPoint) {
        gap: 24px !important;
        min-height: 80px;
        padding: 52px 20px 28px;
    }

    .title {
        font-size: 2.5rem;
        font-weight: 800;

        @media (max-width: $breakPoint) {
            font-size: 20px;
        }
    }
    .price_area {
        display: flex;
        flex-direction: column;
        padding: 24px 80px 48px;
        border-radius: 1rem;
        background-color: white;
        color: $text-black;

        @media (max-width: $breakPoint) {
            padding: 12px 24px 26px 24px;
        }

        .discount {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 8px;

            del {
                font-size: 1.5rem;
                font-weight: 400;

                @media (max-width: $breakPoint) {
                    font-size: 12px;
                }
            }
            .rate {
                padding: 4px 16px;
                border-radius: 8px;
                background-color: $primary;
                font-size: 1.5rem;
                font-weight: 700;
                color: white;

                @media (max-width: $breakPoint) {
                    padding: 2px 4px;
                    border-radius: 4px;
                    font-size: 12px;
                }
            }
        }
        .plan_price {
            display: flex;
            align-items: center;
            gap: 2rem;

            .plan_name {
                font-size: 2rem;
                font-weight: 800;

                @media (max-width: $breakPoint) {
                    font-size: 20px;
                }
            }
            .price {
                font-size: 1.25rem;
                font-weight: 400;

                @media (max-width: $breakPoint) {
                    font-size: 12px;
                }

                b {
                    font-size: 3rem;
                    font-weight: 900;

                    @media (max-width: $breakPoint) {
                        font-size: 24px;
                    }
                }
            }
        }
    }
    .guide {
        margin-top: 1rem;
        font-size: 0.875rem;
        font-weight: 400;
        text-align: center;
        white-space: pre-line;

        @media (max-width: $breakPoint) {
            margin-top: unset;
            font-size: 11px;
        }
    }
}

/* scalability section */
.scalability {
    visibility: hidden;
}
.scalability .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 120px;
}
.scalability .contents_list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    width: 100%;
    margin-bottom: 100px;

    @media (max-width: $breakPoint)  {
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
    }

    .contents_item {
        @include flex-row-center-center;
        position: relative;
        height: 120px;
        border-radius: 16px;
        background-color: #EAEAEA;
        overflow: clip;

        @media (max-width: $breakPoint)  {
            height: 74px;
        }

        & img {
            width: 100%;
            height: 100%;
        }

        .title {
            position: absolute;
            font-size: 1rem;
            line-height: 1.1875rem;
            font-weight: 700;
            color: white;
        }
    }
}

/* ending section */
.ending {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100vw;
    height: 700px;
    background: url("/images/home/ending/background.webp");
    background-size: cover;
    color: white;

    .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
    }

    .btn_area {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-top: 24px;

        button, a {
            padding: 16px 32px;
            border-radius: 16px;
            font-size: 16px;
            font-weight: 700;

            &:nth-child(1) {
                background: linear-gradient(125deg, #612DF5 46.9%, #7122D1 79.11%, #7F18AF 108.49%);
                color: white;
            }
            &:nth-child(2) {
                background-color: white;
                color: $text-black;
            }
        }
    }
}
